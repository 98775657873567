<template>
  <b-row style="background-color: #F5F7FA; padding-top: 20px; padding-bottom: 50px;" class="pt-4 d-flex justify-content-center">
    <b-col style="max-width: 1200px; min-height: 800px;">
      <b-row>

        <b-col lg="6" offset-lg="3">

          <h1 v-if="user.user_id" style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">{{ user.first_name }} {{ user.last_name }}</h1>
          <h1 v-else style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">New User</h1>
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body>
              <b-form @submit.prevent="saveUser" @reset.prevent="onReset">
              <!-- FIRST NAME -->
                <b-form-group
                  id="first-name-group"
                  label="First Name:"
                  label-for="first-name"
                  description=""
                >
                  <p v-show="validation.first_name == false" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter your first name.
                  </p>
                  <b-form-input
                    id="first-name"
                    :class="{ 'error' : validation.first_name == false }"
                    v-model="user.first_name"
                    placeholder=""
                    trim
                  ></b-form-input>
                </b-form-group>

              <!-- LAST NAME -->
                <b-form-group
                  id="last-name-group"
                  label="Last Name:"
                  label-for="last-name"
                  description=""
                >
                  <p v-show="validation.last_name == false" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter your last name.
                  </p>
                  <b-form-input
                    id="last-name"
                    :class="{ 'error' : validation.last_name == false }"
                    v-model="user.last_name"
                    placeholder=""
                    trim
                  ></b-form-input>
                </b-form-group>

              <!-- PHONE -->
                <b-form-group id="phone-group" label="Phone number:" label-for="phone-group">
                  <p v-show="validation.phone == false" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a valid phone number.
                  </p>
                  <b-form-input
                    id="phone"
                    v-model="user.phone"
                    placeholder=""
                    type="tel"
                    :class="{ 'error' : validation.phone == false }"
                  ></b-form-input>
                </b-form-group>

              <!-- PHONE -->
                <b-form-group id="email-group" label="Email:" label-for="email-group">
                  <p v-show="validation.email == false" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a valid email address.
                  </p>
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    placeholder=""
                    type="email"
                    :class="{ 'error' : validation.email == false }"
                  ></b-form-input>
                </b-form-group>

              <!-- PASSWORD -->
                <b-form-group v-show="!user.user_id" id="password-group" label="Password:" label-for="password-group">
                  <p v-show="validation.password == false" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please confirm your password matches and has more than 6 characters.
                  </p>
                  <b-form-input
                    id="password"
                    v-model="user.password"
                    placeholder=""
                    type="password"
                    :class="{ 'error' : validation.password == false }"
                  ></b-form-input>
                </b-form-group>

              <!-- PASSWORD CONFIRM -->
                <b-form-group v-show="!user.user_id" id="password-confirm-group" label="Confirm Password:" label-for="password-confirm-group">
                  <p v-show="validation.password_confirm == false" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please confirm your password matches and has more than 6 characters.
                  </p>
                  <b-form-input
                    id="password-confirm"
                    v-model="user.password_confirm"
                    placeholder=""
                    type="password"
                    :class="{ 'error' : validation.password_confirm == false }"
                  ></b-form-input>
                </b-form-group>                

                <b-button v-show="!processing" type="submit" class="btn btn-primary btn-dash-submit float-right">Save</b-button>
                <b-button v-show="processing" disabled type="submit" class="btn btn-primary btn-dash-submit float-right"><b-spinner class="mr-2" small></b-spinner> Saving...</b-button>
                <b-button @click="cancel" class="btn btn-light float-right mr-3">Cancel</b-button>
              
              </b-form>

            </b-card-body>
          </b-card>

        </b-col>
      </b-row>      
    </b-col>
  </b-row>

</template>

<script>
export default {
  name: 'edit-user',

  created () {
    if(this.$route.params.userId){
      this.getUser();
    }
  },

  data() {
    return {
      processing: false,
      user: {
        user_id: '',
        active: true,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password_confirm: '',
      },
      validation: {
        first_name: true,
        last_name: true,
        email: true,
        phone: true,
        password: true,
        password_confirm: true,
      }
    }    
  },

  metaInfo() {
    return {
      title: this.user.first_name ? this.user.first_name + ' ' + this.user.last_name : 'New User'
    }
  },

  methods: {

    getUser(){
      this.$store.commit('generateApiCreds');

      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'users/id/' + this.$route.params.userId, {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          // console.log(resp);
          this.user = resp.data;
        })
        // .catch(err => {
        //   console.log(err);
        //   self.$router.push('/*');
        // });
    },

    saveUser() {
      this.$store.commit("generateApiCreds");
      const self = this;

      if(!this.validateForm()){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return false;
      }

      this.processing = true;

      const formData = new FormData();

      // kind of confusing since we have the user_id of the person being edited, and the user_id of the person logged in
      formData.append('logged_in_user_id', this.$store.state.auth.userId);
      formData.append('logged_in_user_token', this.$store.state.auth.token);

      formData.append('user_id', this.user.user_id);
      formData.append('active', this.user.active);
      formData.append('first_name', this.user.first_name);
      formData.append('last_name', this.user.last_name);
      formData.append('email', this.user.email);
      formData.append('phone', this.user.phone);
      formData.append('password', this.user.password);
      formData.append('password_confirm', this.user.password_confirm);

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "users", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword,
            },
          })
        .then(function() {
          self.processing = false;
          self.$router.push('/dashboard');
        })
        .catch(function() {
          // alert('We have an error on our site. Please contact support and let us know. Thanks!')
          // console.log(resp);
          alert('error');
        });
    },

    validateForm() {
      this.validation.first_name     = this.user.first_name.length > 0 ? true : false;
      if(this.user.last_name != this.user.first_name){
        this.validation.last_name = this.user.last_name.length > 0 ? true : false;
      } else {
        this.validation.last_name = false;
      }
      this.validation.email     = this.user.email.length > 5 ? true : false;
      this.validation.phone     = this.user.phone.length > 8 ? true : false;

      // We only care about validating password and password confirm fields if this is a new user.
      if(!this.user.user_id){
        if(this.user.password == this.user.password_confirm){
          this.validation.password = this.user.password.length > 6 ? true : false;
          this.validation.password_confirm = this.user.password_confirm.length > 6 ? true : false;
        } else {
          this.validation.password = false;
          this.validation.password_confirm = false;
        }
      } else {
        this.validation.password = true;
        this.validation.password_confirm = true;
      }

      // If any values in our validation object are false, then return false
      return Object.values(this.validation).every(Boolean);
    },

    cancel() {
      this.$router.go(-1);
    },

  },

  computed: {

  }
}
</script>

<style scoped>

.btn-dash-submit {
  background-color: #007cc0;
  border-color: #007cc0;
}

.btn-dash-submit:hover {
  background-color: rgb(23, 92, 142);
  border-color: rgb(23, 92, 142);
}

.error {
  border: 2px solid #d9534f;
}

.btn.disabled {
  cursor:not-allowed;
}

</style>
